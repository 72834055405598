import {environment} from '../../environments/environment';
import * as amplitude from '@amplitude/analytics-browser';
import {Injectable} from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class AmplitudeService {
	private amplitudeApiKey = environment.amplitudeApiKey;
	private isEnableAmplitude = environment.amplitude;
	constructor() {
	}

	initialize() {
		amplitude.init(this.amplitudeApiKey);
	}

	setUserID(userId: string) {
		if (this.isEnableAmplitude) {
			amplitude.setUserId(userId);
		}
	}

	sendEvents(event, eventProperties) {
		if (this.isEnableAmplitude) {
			amplitude.track(event, eventProperties);
		}
	}
}

