import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class WindowService {
	private windowObject: any;
	private deepLinkUrl: any;
	private windowAppObject: any;
	private windowExternalApp: any;
	private deeplinkUrl: string;
	private urlScheme: string;
	private packageName: string;
	private appStoreURL: string;

	load() {
		this.windowObject = window;
		this.windowAppObject = Window;
		this.deepLinkUrl = this.windowObject.location.href;
		this.windowExternalApp = this.windowObject.fma;
	}

	queryWindow(input) {
		return this.windowObject[input];
	}

	getDeepLinkUrl() {
		return this.deepLinkUrl;
	}

	openNativeApp(packageName, OSType, deeplinkUrl, urlScheme, appStoreURL) {
		if (OSType === 'Android') {
			this.windowObject.android.launchExternalApp(packageName, deeplinkUrl);
		} else {
			const jsonObject = {
				action: 'launchExternalApp',
				callback: 1.0,
				deeplinkURL: deeplinkUrl,
				urlScheme: urlScheme,
				appStoreURL: appStoreURL,
			};
			document.addEventListener('PacificDidReceiveNativeCallback', (event) => {
			});
			this.windowObject = window;
			this.windowObject.webkit.messageHandlers.pacific.postMessage(jsonObject, window.location.origin);
		}
	}

	setDeeplinkParams(wallboxType) {
		if (wallboxType === '80A') {
			this.deeplinkUrl = 'fordchargestationpro://app';
			this.urlScheme = 'fordchargestationpro://';
			this.packageName = 'com.siemens.ford80A';
			this.appStoreURL = 'https://apps.apple.com/us/app/chargestationpro/1607972327';
		} else {
			this.deeplinkUrl = 'webasto://FCSETUP?calling-app=fp';
			this.urlScheme = 'webasto://';
			this.packageName = 'com.webasto.ford';
			this.appStoreURL = 'https://apps.apple.com/us/app/ford-charge-station-setup/id1497123314';
		}
		return {
			deeplinkUrl: this.deeplinkUrl,
			urlScheme: this.urlScheme,
			packageName: this.packageName,
			appStoreURL: this.appStoreURL
		};
	}

	findDeviceType() {
		let OSType;
		if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
			OSType = 'Android';
		}
		if (navigator.userAgent.toLowerCase().indexOf('iphone') > -1) {
			OSType = 'iOS';
		}
		return OSType;
	}
}
