import {AmplitudeService} from '../_services/amplitude.service';
import {Injectable} from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class JwtInterceptorSendEvents {

	constructor(private amplitudeService: AmplitudeService) {
	}

	sendErrors(error: any) { console.log(error);
		const event = 'webapp error logs pushed';
		const eventProperties = {
			'errorType': error.error.error.attributes.exception,
			'errorCode': error.error.error.errorCode,
			'errorMessage': error.message,
			'errorName': error.name,
			'errorStatus': error.status,
			'errorUrl': error.url
		};
		this.amplitudeService.sendEvents(event, eventProperties);
	}

}
