import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig} from '@angular/platform-browser';
import {NgModule, Injectable} from '@angular/core';
import {RouteReuseStrategy, RouterModule} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {JwtInterceptor} from './_helpers/jwt.interceptor';
import {ErrorInterceptor} from './_helpers/error.interceptor';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';
import {MessageService} from 'primeng/api';
import {AppRoutingModule} from './app-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TableModule} from 'primeng/table';
import {ChartModule} from 'primeng/chart';
import {ButtonModule} from 'primeng/button';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DropdownModule} from 'primeng/dropdown';
// import {GrowlModule} from 'primeng/';
import {InputSwitchModule} from 'primeng/inputswitch';
import {MenubarModule} from 'primeng/menubar';
import {MessageModule} from 'primeng/message';
import {MessagesModule} from 'primeng/messages';
import {SidebarModule} from 'primeng/sidebar';
import {SliderModule} from 'primeng/slider';
import {DialogModule} from 'primeng/dialog';
import {ToastModule} from 'primeng/toast';
import {AccordionModule} from 'primeng/accordion';
import {CheckboxModule} from 'primeng/checkbox';
import {TabViewModule} from 'primeng/tabview';
import {GalleriaModule} from 'primeng/galleria';
import {TooltipModule} from 'primeng/tooltip';
import {InputTextModule} from 'primeng/inputtext';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {NgxUsefulSwiperModule} from 'ngx-useful-swiper';
import {LoaderComponent} from './loader/loader.component';
import {LoaderInterceptorService} from './_services/loaderInterceptorService.service';
import {AppStartupComponent} from './app-startup/app-startup.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NgxSliderModule} from '@angular-slider/ngx-slider';
import {DatePipe, registerLocaleData} from '@angular/common';
import {CacheRouteReuseStrategy} from './_helpers/cache-route-reuse.strategy';
import {SetupConfigConfirmComponent} from './setup-config-confirm/setup-config-confirm.component';
import {AppFooterComponent} from './app-footer/app-footer.component';
import {GeneralErrorComponent} from './general-error/general-error.component';
import localeCsCZ from '@angular/common/locales/cs';
import localeDaDK from '@angular/common/locales/da';
import localeDeDE from '@angular/common/locales/de';
import localeElGR from '@angular/common/locales/el';
import localeEsES from '@angular/common/locales/es';
import localeEsUS from '@angular/common/locales/es-US';
import localeFiFI from '@angular/common/locales/fi';
import localeFrCA from '@angular/common/locales/fr-CA';
import localeFrFR from '@angular/common/locales/fr';
import localeHuHU from '@angular/common/locales/hu';
import localeItIT from '@angular/common/locales/it';
import localeNbNO from '@angular/common/locales/nb';
import localeNlBE from '@angular/common/locales/nl-BE';
import localeNlNL from '@angular/common/locales/nl';
import localePlPL from '@angular/common/locales/pl';
import localePtPT from '@angular/common/locales/pt-PT';
import localeRoRO from '@angular/common/locales/ro';
import localeSlSI from '@angular/common/locales/sl';
import localeSvSE from '@angular/common/locales/sv';
import {AppSetupComponent} from './app-setup/app-setup.component';

registerLocaleData(localeCsCZ, 'cs-CZ');
registerLocaleData(localeDaDK, 'da-DK');
registerLocaleData(localeDeDE, 'de-DE');
registerLocaleData(localeElGR, 'el-GR');
registerLocaleData(localeEsES, 'es-ES');
registerLocaleData(localeEsUS, 'es-US');
registerLocaleData(localeFiFI, 'fi-FI');
registerLocaleData(localeFrCA, 'fr-CA');
registerLocaleData(localeFrFR, 'fr-FR');
registerLocaleData(localeHuHU, 'hu-HU');
registerLocaleData(localeItIT, 'it-IT');
registerLocaleData(localeNbNO, 'nb-NO');
registerLocaleData(localeNlBE, 'nl-BE');
registerLocaleData(localeNlNL, 'nl-NL');
registerLocaleData(localePlPL, 'pl-PL');
registerLocaleData(localePtPT, 'pt-PT');
registerLocaleData(localeRoRO, 'ro-RO');
registerLocaleData(localeSlSI, 'sl-SI');
registerLocaleData(localeSvSE, 'sv-SE');


@Injectable()
export class HammerConfig extends HammerGestureConfig {
	overrides = {
		'swipe': {velocity: 0.4, threshold: 20}
	} as any;
}

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export function playerFactory() {
	return player;
}


@NgModule({
	declarations: [
		AppComponent,
		SetupConfigConfirmComponent,
		LoaderComponent,
		AppStartupComponent,
		AppSetupComponent,
		AppFooterComponent,
		GeneralErrorComponent
	],
	imports: [
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		BrowserAnimationsModule,
		RouterModule,
		AppRoutingModule,
		MenubarModule,
		// GrowlModule,
		FormsModule,
		HttpClientModule,
		ReactiveFormsModule,
		TableModule,
		ButtonModule,
		DropdownModule,
		InputSwitchModule,
		MessageModule,
		MessagesModule,
		ChartModule,
		DialogModule,
		ToastModule,
		AccordionModule,
		CheckboxModule,
		TabViewModule,
		GalleriaModule,
		NgxUsefulSwiperModule,
		InputTextModule,
		TooltipModule,
		AutoCompleteModule,
		SliderModule,
		SidebarModule,
		NgxSliderModule,
		LottieModule.forRoot({ player: playerFactory }),
		ConfirmDialogModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient]
			}
		})
	],
	providers: [MessageService, DatePipe,
		{provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
		{provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
		{provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true},
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: HammerConfig,
		},
		{
			provide: RouteReuseStrategy,
			useClass: CacheRouteReuseStrategy
		}
	],
	exports: [TranslateModule],
	bootstrap: [AppComponent]
})
export class AppModule {
}
