import {Component, HostListener, Inject, OnInit, Optional, PLATFORM_ID, VERSION} from '@angular/core';
import { environment } from '../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import {isPlatformBrowser, isPlatformServer, Location} from '@angular/common';
import { MessageService } from 'primeng/api';
import { WindowService } from './_services/window.service';
import { FmaService } from './_services/fmaLibrary.service';
import { WallboxService } from './_services/wallbox.service';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from './_services/loader.service';
import { AmplitudeService } from './_services/amplitude.service';
import { AppSendEvents } from './app-send-events';
import {makeStateKey, TransferState} from '@angular/platform-browser';
const catKey = makeStateKey<string>('catokenKey');
const refreshKey = makeStateKey<string>('refrestokenKey');
const catKeyfromUrl = makeStateKey<string>('catokenKeyFromUrl');
const refreshKeyfromUrl = makeStateKey<string>('refreshtokenKeyFromUrl');
const fccsidKey = makeStateKey<string>('fccsidKey');
const fccsacKey = makeStateKey<string>('fccsacKey');
const wtypeKey = makeStateKey<string>('wtypeKey');

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
	hamMenu: boolean;
	version = VERSION.full;
	webview = environment.webview;
	production = environment.production;
	siemens = environment.siemens;
	catToken: any;
	refreshToken: any;
	userGuid: any;
	stationId: any;
	accessCode: any;
	paramsSubscription: any;
	queryParams: any;
	repeatCounter: number;
	userLanguage: string;
	languages = [];
	isUserFromNA: boolean;
	isUserFromUSA: boolean;
	userCountry: string;
	vendor = environment.vendorCodes;
	showManualSetupFlow: any;

	constructor(private readonly router: Router,
				private readonly route: ActivatedRoute,
				private readonly location: Location,
				private readonly messageService: MessageService,
				private readonly wallboxService: WallboxService,
				private readonly windowService: WindowService,
				private readonly fmaService: FmaService,
				private readonly translate: TranslateService,
				private readonly loaderService: LoaderService,
				private readonly amplitudeService: AmplitudeService,
				private readonly appSendEvents: AppSendEvents,
				@Inject(PLATFORM_ID) private platformId: Object,
				@Optional() @Inject('catoken') public catoken: string,
				@Optional() @Inject('refrestoken') public refrestoken: string,
				@Optional() @Inject('fordctToken') public fordctToken: string,
				@Optional() @Inject('fordrefreshToken') public fordrefreshToken: string,
				@Optional() @Inject('FCCSID') public FCCSID: string,
				@Optional() @Inject('FCCSAC') public FCCSAC: string,
				@Optional() @Inject('WTYPE') public WTYPE: string,
				private readonly transferState: TransferState) {
		translate.setDefaultLang('en-US');
	}

	ngOnInit() {
		this.repeatCounter = 0;
		this.cleanupQueryParams();
		this.fetchTokensAndDeeplinkParamsFromServerOrBrowser();
		this.amplitudeService.initialize();
	}

	setTransferStateValue(key, value) {
		this.transferState.set(key, value);
	}

	storeTokensInTrasferState() {
		if (this.catoken) {
			this.setTransferStateValue(catKey, this.catoken);
			this.setTransferStateValue(refreshKey, this.refrestoken);
		}
		if(this.fordctToken) {
			this.setTransferStateValue(catKeyfromUrl, this.fordctToken);
			this.setTransferStateValue(refreshKeyfromUrl, this.fordrefreshToken);
		}
	}

	storeStationIDAndAccessCodeInTransferState() {
		if(this.FCCSID && this.FCCSAC) {
			this.setTransferStateValue(fccsidKey, this.FCCSID);
			this.setTransferStateValue(fccsacKey, this.FCCSAC);
		}
	}

	storeWallboxTypeInTransferState() {
		if(this.WTYPE) {
			this.transferState.set(wtypeKey, this.WTYPE);
		}
	}

	getTokensFromTransferState() {
		this.catoken = this.transferState.get(catKey, '');
		this.refrestoken = this.transferState.get(refreshKey, '');
		this.fordctToken = this.transferState.get(catKeyfromUrl, '');
		this.fordrefreshToken = this.transferState.get(refreshKeyfromUrl, '');
		if(this.catoken) {
			this.wallboxService.storeCatToken(this.catoken, this.refrestoken, true);
		}
		if(this.fordctToken) {
			this.wallboxService.storeCatToken(this.fordctToken, this.fordrefreshToken, true);
		}
	}

	getTokenFromFMA() {
		if(!this.catoken && !this.fordctToken) {
			this.fetchTokensFromFMA();
		}
	}

	fetchTokensAndDeeplinkParamsFromServerOrBrowser() {
		if (this.checkIfApplicationLoadsFromServer()) {
			this.storeTokensInTrasferState();
			this.storeStationIDAndAccessCodeInTransferState();
			this.storeWallboxTypeInTransferState();
		}else if (this.checkIfApplicationLoadsFromBrowser()) {
			this.WTYPE = this.transferState.get(wtypeKey, '');
			this.getTokensFromTransferState();
			this.getTokenFromFMA();
			this.handleDeeplinkFromSetupApp();
			this.handleAuthTokensOnWebView();
		}
	}

	checkIfApplicationLoadsFromServer() {
		return isPlatformServer(this.platformId);
	}

	checkIfApplicationLoadsFromBrowser() {
		return isPlatformBrowser(this.platformId);
	}

	handleDefaultRedirects() {
		if (this.queryParams !== null && this.WTYPE === '80A') {
			this.router.navigate(['app-setup'], { queryParams: { WTYPE: '80A' } });
		} else if (this.stationId !== null && this.accessCode !== null) {
			this.appSendEvents.sendConfirmWallboxSetupEvents(this.getWallboxType(this.stationId));
			this.router.navigate(['wallbox-setup/app-steps-add-device']);
		} else if (this.router.url === '' || this.router.url === '/') {
			this.router.navigate(['app-startup'], { queryParamsHandling: 'preserve'});
		}
	}

	handleAuthTokensOnWebView() {
		if (!this.catoken && !this.catToken && this.repeatCounter < 20) {
			this.fetchTokensFromFMA();
			this.loaderService.show();
			this.repeatCounter++;
			setTimeout(() => this.handleAuthTokensOnWebView(), 250);
		} else {
			this.getUsersLanguage();
		}
	}

	fetchTokensFromFMA() {
		this.windowService.load();
		this.catToken = this.fmaService.getAccessTokenFromWindow();
		this.refreshToken = this.fmaService.getRefreshTokenFromWindow();
		this.userGuid = this.fmaService.getUserGuidFromWindow();
		if (this.catToken) {
			this.wallboxService.storeCatToken(this.catToken, this.refreshToken, true);
		}
		if (this.userGuid) {
			this.wallboxService.storeUserGuid(this.userGuid);
		}
	}

	handleDeeplinkFromSetupApp() {
		this.FCCSID = this.transferState.get(fccsidKey, '');
		this.FCCSAC = this.transferState.get(fccsacKey, '');
		this.stationId = this.FCCSID ? this.FCCSID : null;
		this.accessCode = this.FCCSAC ? this.FCCSAC : null;
		this.WTYPE = this.WTYPE;
		this.validateStationId();
	}

	cleanupQueryParams() {
		this.queryParams = null;
		this.stationId = null;
		this.accessCode = null;
		this.WTYPE = null;
	}

	validateStationId() {
		if (this.stationId !== null && this.accessCode !== null) {
			const chargePointData = {
				'serialNumber': this.stationId,
				'accessCode': this.accessCode,
				'chargePointAddress': {}
			};
			this.wallboxService.storeAddWallboxData(chargePointData);
		}
	}

	getUsersLanguage() {
		this.wallboxService.getUserProfile().subscribe(
			(data: any) => {
				this.handleUserCountry(data);
				this.handleUserProfileData(data);
				this.hideLoaderAndHanldeRedirects();
			});
	}

	handleUserProfileData(data: any) {
		this.userLanguage = data.result.profile.preferredLanguage;
		this.userCountry = data.result.profile.country;
		this.languages = this.wallboxService.getAllLanguages();
		const defaultLanguage = this.wallboxService.getDefaultLanguage();
		let languagetoBeStored = defaultLanguage;
		if (this.checkUserLanguageIsInTranslationList()) {
			this.translate.use(this.userLanguage);
			languagetoBeStored = this.userLanguage;
		}
		this.wallboxService.storeUserLanguage(languagetoBeStored);
		this.wallboxService.storeUserCountry(this.userCountry);
		const userId = data.result.profile.userGuid;
		this.amplitudeService.setUserID(userId.toLowerCase());
	}

	handleUserCountry(data) {
		const country = data.result.profile.country;
		this.isUserFromNA = this.checkCountryIsNA(country);
		if (this.siemens) {
			this.wallboxService.setIsUserFromNA(this.isUserFromNA);
		}
	}

	hideLoaderAndHanldeRedirects() {
		if (this.webview === true) {
			this.loaderService.hide();
			this.handleDefaultRedirects();
		}
	}

	checkUserLanguageIsInTranslationList() {
		return this.languages.includes(this.userLanguage);
	}

	checkCountryIsNA(country: string) {
		const naCountries = this.wallboxService.getNACountries();
		this.isUserFromUSA = country === 'USA';
		this.wallboxService.setIsUserFromUSA(this.isUserFromUSA);
		if (naCountries.includes(country)) {
			return true;
		} else {
			return false;
		}
	}

	getWallboxType(serialNumber) {
		const vendorCode = serialNumber.substring(0, 3).toUpperCase();
		return this.vendor['SIEMENS'].includes(vendorCode) ? '80A' : '48A';
	}

	@HostListener('window:resize', ['$event']) onResize(event?) {
		if (event.target.innerWidth > 767) {
			this.hamMenu = false;
		}
	}
}
