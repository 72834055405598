import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WallboxService } from '../_services/wallbox.service';
import { WindowService } from '../_services/window.service';
import { AppSetupSendEvents } from './app-setup-send-events';

@Component({
	selector: 'app-setup',
	templateUrl: './app-setup.component.html',
	styleUrls: ['./app-setup.component.css']
})
export class AppSetupComponent implements OnInit {
	images: any[];
	addWallboxConfirm = false;
	idAppDownload = 'app-download';
	idUseSetup = 'use-setup';
	idBluetoothConnect = 'bluetooth-connect';
	idWifiNetwork = 'wifi-network';
	idConnectFordpass = 'connect-fordpass';
	letsGoBtn = 'letsGoBtn';
	paramsSubscription: any;
	wallboxType: string;
	showManualSetupFlow: string;
	isUSAUser: boolean;

	constructor(private readonly wallboxService: WallboxService,
				private readonly router: Router,
				private readonly route: ActivatedRoute,
				private readonly windowService: WindowService,
				private readonly appSetupSendEvents: AppSetupSendEvents) { }

	ngOnInit() {
		this.paramsSubscription = this.route.queryParams.subscribe(params => this.handleQueryParams(params));
		this.setWallboxType();
		this.showManualSetupFlow = this.wallboxService.getShowManualSetupFlow();
		this.isUSAUser = this.wallboxService.getIsUserFromUSA();
	}

	setWallboxType() {
		if (this.wallboxType) {
			this.wallboxService.setWallboxType(this.wallboxType);
		} else {
			this.wallboxType = this.wallboxService.getWallboxType();
		}
	}

	handleQueryParams(params) {
		this.wallboxType = params.WTYPE;
		if (this.wallboxType) {
			return this.wallboxType;
		}
	}

	openSetupAppForAddWallbox() {
		this.addWallboxConfirm = false;
		let OSType;
		OSType = this.windowService.findDeviceType();
		const deeplinkParams = this.windowService.setDeeplinkParams(this.wallboxType);
		this.windowService.openNativeApp(deeplinkParams.packageName, OSType, deeplinkParams.deeplinkUrl, deeplinkParams.urlScheme, deeplinkParams.appStoreURL);
		this.appSetupSendEvents.sendOpenSetupTapEvents(this.wallboxType);
	}

	addWallboxManually() {
		const chargePointData = {
			'serialNumber': '',
			'username': this.wallboxService.getUserGuid(),
			'chargePointAddress': {}
		};
		this.wallboxService.storeAddWallboxData(chargePointData);
		this.appSetupSendEvents.sendConnectFordpassManuallyTapEvents(this.wallboxType);
		this.router.navigate(['wallbox-setup/app-steps-add-device']);
	}

	showAddWallboxConfirm() {
		this.addWallboxConfirm = true;
		this.appSetupSendEvents.sendSetupLetsGoTapEvents(this.wallboxType);
	}

	closeAddWallbox() {
		this.addWallboxConfirm = false;
		this.appSetupSendEvents.sendCancelSetupTapEvents(this.wallboxType);
	}

	goBack() {
		this.router.navigate(['app-startup'], { queryParamsHandling: 'merge'});
	}
}
