<div class="fds-layout-grid" [hidden]="isLoading">
	<div class="fds-layout-grid__inner">
		<div class="fds-layout-grid__cell--span-12">
			<div class="setup" *ngIf="!isUserFromNA; else NAWallbox">
				<img src="assets/image/wallbox-splash.png" class="wallbox" alt="Wallbox" title="Wallbox">
				<div class="content">
					<div class="heading">{{'CHARGE_FORWARD' | translate}}</div>
					<div class="text">{{'STARTUP_DESC' | translate}}.</div>
				</div>
				<div class="start-up">
					<button class="fmc-button" data-dark type="submit" (click)="goToSetup()" [id]="startSetupBtn">
						{{'START_SETUP' | translate}}
						<span class="fds-icon fds-icon--32 fds-icon--offset-right-sm fds-font--ford-icons__chevron-right"></span>
					</button>
					<div class="learn-more">
						<button class="fmc-text-button" type="submit" (click)="goToAppStep()" data-dark [id]="learnMoreBtn">
							{{'LEARN_MORE' | translate}}
						</button>
					</div>
				</div>
			</div>


			<ng-template #NAWallbox>
				<div class="appstartup-80Awallbox">
				<div class="content">
					<div class="heading">{{'CHARGE_FORWARD' | translate}}</div>
					<div class="text text-80A">{{'STARTUP_DESC_80A' | translate}}.</div>
					<div class="wallbox-80A">
						<img src="assets/image/wallbox-splash-80A.svg" class="wallbox" alt="Wallbox" title="Wallbox">
					</div>
				</div>
				<div class="start-up">
					<button class="fmc-button" type="submit" (click)="goToSelectYourStation()" [id]="startSetupBtn">
						{{'GET_STARTED' | translate}}<span class="fds-icon fds-icon--32 fds-icon--offset-right-sm fds-font--ford-icons__chevron-right"></span>
					</button>
					<div class="learn-more">
						<button class="fmc-button fmc-button--outlined-customised" (click)="goToAppStep()" [id]="learnMoreBtn">
							{{'LEARN_MORE' | translate}}
						</button>
					</div>
				</div>
				</div>
			</ng-template>

		</div>
	</div>
</div>