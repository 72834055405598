<div style="height: 50px"></div>
<div class="footer height-padding">
	<div class="footer-left">
		<span class="footer-btn" id="pageBackBtn" (click)="goToPrevPage()">
			<img src="assets/image/back-arrow.svg" alt="Information" title="Information">
		</span>
		<span class="footer-btn" id="pageNextBtn" (click)="goToNextPage()" *ngIf="historyDepth > 0;else forwardNavigationDisabled">
			<img src="assets/image/forward-arrow.svg" alt="Information" title="Information">
		</span>
		<ng-template #forwardNavigationDisabled>
			<span class="footer-btn">
				<img src="assets/image/forward-arrow-disabled.svg" alt="Information" title="Information">
			</span>
		</ng-template>
	</div>
	<div class="footer-right">
		<img src="assets/image/refresh.svg" alt="Information" title="Information" id="pageRefreshBtn" (click)="refreshPage()">
	</div>
</div>