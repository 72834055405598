import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import {Subscription} from 'rxjs';
import {LoaderService} from '../_services/loader.service';
import {LoaderState} from './loader';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
	selector: 'app-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.css']
})

export class LoaderComponent implements OnInit, OnDestroy {
	show = false;
	private subscription: Subscription;
	public lottieConfig: AnimationOptions;
	private anim: any;

	constructor(private readonly loaderService: LoaderService) {
		this.lottieConfig = {
			path: 'assets/loader/spinner.json',
			autoplay: true,
			loop: true
		};
	}

	ngOnInit() {
		this.subscription = this.loaderService.loaderState
			.subscribe((state: LoaderState) => {
				this.show = state.show;
			});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	handleAnimation(animationItem: AnimationItem): void {
		this.anim=animationItem;
	}

}
