<div [class.hidden]="!show">
	<div class="overlay"></div>
	<div>
		<div class="loader">
			<div *ngIf="show">
				<div class="loading">{{'LOADING' | translate}}</div>
				<ng-lottie [options]="lottieConfig" (animationCreated)="handleAnimation($event)" width="500px"
						   height="800px"></ng-lottie>
			</div>
		</div>
	</div>
</div>