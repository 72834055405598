import { RouteReuseStrategy } from '@angular/router/';
import { ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';
import { Injectable } from '@angular/core';
@Injectable()
export class CacheRouteReuseStrategy implements RouteReuseStrategy {
	storedRouteHandles = new Map<string, DetachedRouteHandle>();
	allowRetriveCache = {
		'wallbox-details-dashboard': true
	};
	strWallboxDetailsDashboardRout = 'wallbox-details-dashboard';
	shouldReuseRoute(before: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
		if (this.getPath(before) === 'charge-events' && this.getPath(curr) === this.strWallboxDetailsDashboardRout) {
			this.allowRetriveCache[this.strWallboxDetailsDashboardRout] = true;
		} else {
			this.allowRetriveCache[this.strWallboxDetailsDashboardRout] = false;
		}
		return before.routeConfig === curr.routeConfig;
	}
	retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
		return this.storedRouteHandles.get(this.getPath(route)) as DetachedRouteHandle;
	}
	shouldAttach(route: ActivatedRouteSnapshot): boolean {
		const path = this.getPath(route);
		if (this.allowRetriveCache[path]) {
			return this.storedRouteHandles.has(this.getPath(route));
		}
		return false;
	}
	shouldDetach(route: ActivatedRouteSnapshot): boolean {
		const path = this.getPath(route);
		if (this.allowRetriveCache.hasOwnProperty(path)) {
			return true;
		}
		return false;
	}
	store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
		this.storedRouteHandles.set(this.getPath(route), detachedTree);
	}
	private getPath(route: ActivatedRouteSnapshot): string {
		if (route.routeConfig !== null && route.routeConfig.path !== null) {
			return route.routeConfig.path;
		}
		return '';
	}
}
