import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { WallboxService } from '../_services/wallbox.service';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import {JwtInterceptorSendEvents} from '../_services/jwt-interceptor-send-events';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
	private readonly refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	constructor(private readonly wallboxService: WallboxService,
				private readonly http: HttpClient,
				private readonly router: Router,
				private jwtInterceptorSendEvents: JwtInterceptorSendEvents) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (this.wallboxService.getCatToken() && !request.url.includes('cat-with-refresh-token') && !request.url.includes('autosuggest.search.hereapi.com') && !request.url.includes('lookup.search.hereapi.com')) {
			request = this.addAppIdHeaderToken(request, this.wallboxService.getCatToken());
		} else if (request.url.includes('autosuggest.search.hereapi.com') || request.url.includes('lookup.search.hereapi.com')){
			request = this.addHereMapTokenInHeader(request);
		}
		return next.handle(request).pipe(catchError(error => {
			this.jwtInterceptorSendEvents.sendErrors(error);
			if (this.getErrorCode(error) === 'ERR016') {
				return this.handle401Error(request, next);
			} else if (this.getErrorCode(error) === '503') {
				this.router.navigate(['error']);
			} else {
				return throwError(error);
			}
		}));
	}

	getErrorCode(error){
		if (error.error.error !== undefined && error.error.error.errorCode !== null) {
			return error.error.error.errorCode;
		} else {
			return null;
		}
	}

	private addAppIdHeaderToken(request: HttpRequest<any>, token: string) {
		return request.clone({
			setHeaders: {
				'Auth-Token': this.wallboxService.getCatToken(),
				'Application-Id': this.wallboxService.getAppId()
			}
		});
	}

	private addHereMapTokenInHeader(request: HttpRequest<any>) {
		return request.clone({
			setHeaders: {
				'Authorization': 'Bearer ' + this.wallboxService.getHereMapAccessToken()
			}
		});
	}

	private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
		this.refreshTokenSubject.next(null);

		const params = {
			'clientId': 'ee937fbd-8759-41f7-a2a3-7f3efd2ba9ae',
			'refresh_token': this.wallboxService.getRefreshToken()
		};

		return this.wallboxService.getCatTokenByRefreshToken(params).pipe(
			switchMap((token: any) => {
				this.refreshTokenSubject.next(token.access_token);
				return next.handle(this.addAppIdHeaderToken(request, token.access_token));
			}));
	}
}
