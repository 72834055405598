import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { WallboxService } from '../_services/wallbox.service';

@Component({
	selector: 'app-footer',
	templateUrl: './app-footer.component.html',
	styleUrls: ['./app-footer.component.css']
})
export class AppFooterComponent {
	historyDepth = 0;
	subscription: Subscription;

	constructor(private readonly router: Router,
				private readonly location: Location,
				private readonly wallboxService: WallboxService) { }

	goToPrevPage() {
		const currentUrl = this.location.path().split('?')[0];
		this.subscription = this.wallboxService.getChargePointLiveStatusSubscription();
		if (this.subscription && currentUrl !== '/charge-events') {
			this.subscription.unsubscribe();
		}
		this.historyDepth += 1;
		this.location.back();
	}

	goToNextPage() {
		const currentUrl = this.location.path().split('?')[0];
		this.subscription = this.wallboxService.getChargePointLiveStatusSubscription();
		if (this.subscription && currentUrl !== '/wallbox-details-dashboard/own') {
			this.subscription.unsubscribe();
		}
		this.historyDepth -= 1;
		this.location.forward();
	}

	refreshPage() {
		const currentUrl = this.location.path().split('?')[0];
		if (currentUrl === '/wallbox-setup/app-steps-add-device') {
			this.router.navigate(['app-startup']);
		} else {
			window.location.reload();
		}
	}

}
