<div class="error-page">
	<div class="error-content">
		<div class="fds-transform--uppercase page-not-found-title">
			{{'YOU_HAVE_HIT_A_ROADBLOCK' | translate}}
		</div>
		<div class="page-not-found-desc">
			{{'THE_PAGE_YOU_ARE_LOOKING_FOR_DOES_NOT_EXIST' | translate}}.
		</div>
		<div class="page-not-found-image">
			<img src="assets/image/page-not-exists.svg" alt="Unauthorized Error" title="Unauthorized Error">
		</div>
		<button class="fmc-button" id="retryBtn" type="submit" (click)="refreshPage()">
			{{'RETRY' | translate}}
		</button>
	</div>
</div>