import {AmplitudeService} from '../_services/amplitude.service';
import {Injectable} from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class AppSetupSendEvents {

	constructor(private amplitudeService: AmplitudeService) {
	}

	sendConnectFordpassManuallyTapEvents(wallboxType: string) {
		const connectFordPassEvent = 'wallbox connect to fordpass manually tapped';
		const connectFordPassEventProperties = {
			'screenName': 'add charge station',
			'wallboxType': wallboxType === '48A' ? 'WS48A' : 'SWA80A'
		};
		this.amplitudeService.sendEvents(connectFordPassEvent, connectFordPassEventProperties);
	}

	sendOpenSetupTapEvents(wallboxType: string) {
		const openSetupEvent = 'wallbox open setup application tapped';
		const openSetupEventProperties = {
			'screenName': 'fordPassWantsToOpenSetupApp',
			'wallboxType': wallboxType === '48A' ? 'WS48A' : 'SWA80A'
		};
		this.amplitudeService.sendEvents(openSetupEvent, openSetupEventProperties);
	}

	sendCancelSetupTapEvents(wallboxType: string) {
		const cancelEvent = 'wallbox cancel setup application tapped';
		const cancelEventProperties = {
			'screenName': 'fordPassWantsToOpenSetupApp',
			'wallboxType': wallboxType === '48A' ? 'WS48A' : 'SWA80A'
		};
		this.amplitudeService.sendEvents(cancelEvent, cancelEventProperties);
	}

	sendSetupLetsGoTapEvents(wallboxType) {
		const setupGoEvent = 'wallbox setup lets go tapped';
		const setupGoEventProperties = {
			'screenName': 'add charge station',
			'wallboxType': wallboxType === '48A' ? 'WS48A' : 'SWA80A'
		};
		this.amplitudeService.sendEvents(setupGoEvent, setupGoEventProperties);
	}


}
