<div class="fds-layout-grid">
	<div class="fds-layout-grid__inner">
		<div class="fds-layout-grid__cell--span-12">
			<div class="content-position__add-wallbox">
				<div class="setup">
					<div class="content">
						<div class="heading fds-transform--uppercase">{{'SETUP_AND_CONFIGURATION' | translate}}</div>
						<div class="text">{{'SETUP_AND_CONFIGURATION_TEXT_DESC' | translate}}.</div>
						<div class="setup-image">
							<img src="assets/image/setup-config.svg" class="leaving-icons" alt="Download"
								 title="Download">
						</div>
					</div>

				</div>
			</div>

			<div class="bottom-fixed goto-setup-btn">
				<button class="fmc-button" type="submit" id="letsGoBtn" (click)="showConfirmSetupConfig()">
					{{'LETS_GO' | translate}}<span class="fds-icon fds-font--ford-icons__chevron-right fds-icon--offset-right"></span>
				</button>
			</div>

			<div class="fds-layout-grid__cell--span-12 app-setup-dialog">
				<p-dialog [(visible)]="setupConfigConfirm" [modal]="true" [showHeader]="false" [style]="{width: '80vw'}">
					<p class="setup-confirm-box-text" *ngIf="wallboxType === '48A'; else siemensSetupappConfirmMsg" [innerHTML]="'FORDPASS_WANTS_TO_OPEN_CHARGE_STATION' | translate"></p>
					<ng-template #siemensSetupappConfirmMsg>
						<p class="setup-confirm-box-text" [innerHTML]="'FORDPASS_WANTS_TO_OPEN_CHARGE_STATION_PRO' | translate"></p>
					</ng-template>
					<div class="setup-config">
						<a (click)="openWebastoAppForAddWallbox()" class="setup-confirm-button fds-transform--uppercase" id="openBtn">{{'OPEN' | translate}}</a>
						<a (click)="closeSetupConfig()" class="setup-confirm-button fds-transform--uppercase" id="cancelBtn">{{'CANCEL' | translate}}</a>
					</div>
				</p-dialog>
			</div>
		</div>

	</div>
</div>
