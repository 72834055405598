<div *ngIf="showManualSetupFlow != 'true'; else tempSetupFlow">
<div class="fds-layout-grid">
	<div class="fds-layout-grid__inner">
		<div class="fds-layout-grid__cell--span-12 app-setup">
			<div class="content-position">
				<div class="setup setup-margin">
					<div class="content" *ngIf="wallboxType === '48A'; else siemensWallbox">
						<div class="heading">{{'STEP_1' | translate}}</div>
						<div class="row">
							<div class="text lighter" [id]="idAppDownload">{{'DOWNLOAD_FORD_CONNECTED_CHARGE_STATION' | translate}}<span
								class="superscript">TM</span> {{'SETUP_APP_TO_GET_STARTED' | translate}}.
							</div>
						</div>
						<div class="row">
							<div class="app-icon">
								<img src="assets/image/app-icon.png" alt="Wallbox" title="Wallbox download">
							</div>
						</div>
						<div class="heading">{{'STEP_2' | translate}}</div>
						<div class="row">
							<div class="text lighter" [id]="idUseSetup">{{'USE_SETUP_APP_DESC' | translate}}.<span class="superscript">TM</span>
							</div>
						</div>
						<div class="row">
							<img src="assets/image/bluetooth.svg" alt="Download" title="Download">
							<div class="text connect-text" [id]="idBluetoothConnect">{{'CONNECT_YOUR_PHONE_VIA_BLUETOOTH' | translate}}<span class="superscript">®</span></div>
						</div>
						<div class="row">
							<img src="assets/image/wifi.svg" alt="Download" title="Download">
							<div class="text connect-text" [id]="idWifiNetwork">{{'FIND_YOUR_HOME_WI_FI_NETWORK' | translate}}</div>
						</div>
						<div class="row">
							<img src="assets/image/fordpass.svg" alt="Download" title="Download">
							<div class="text connect-text" [id]="idConnectFordpass">{{'CONNECT_YOUR_CHARGE_STATION_TO_YOUR_FORDPASS_ACCOUNT' | translate}}
							</div>
						</div>
						<br>
						<div class="bluetooth-trademark">{{'THE_BLUETOOTH_WORD_MARK_IS_A_TRADEMARK' | translate}}.</div>
						<br><br>
					</div>

					<ng-template #siemensWallbox>
						<div class="content">
						<div class="heading">{{'STEP_1' | translate}}</div>
						<div class="row">
							<div class="text lighter" [id]="idAppDownload">{{'DOWNLOAD_FORD_CONNECTED_CHARGE_STATION_80A' | translate}}.
							</div>
						</div>
						<div class="row">
							<div class="app-icon">
								<picture class="picture-margin">
									<source media="(min-width: 200px)"
											srcset="assets/image/app-icon-80A-mdpi.png">
									<source media="(min-width: 320px)"
											srcset="assets/image/app-icon-80A-mdpi.png">
									<source media="(min-width: 480px)"
											srcset="assets/image/app-icon-80A-hdpi.png">
									<source media="(min-width: 720px)"
											srcset="assets/image/app-icon-80A-xhdpi.png">
									<source media="(min-width: 960px)"
											srcset="assets/image/app-icon-80A-xxhdpi.png">
									<source media="(min-width: 1440px)"
											srcset="assets/image/app-icon-80A-xxxhdpi.png">
									<img src="assets/image/app-icon-80A-mdpi.png" class="dashboard-banner"
										 alt="Wall Box" title="Wall Box">
								</picture>
							</div>
						</div>
						<div class="heading">{{'STEP_2' | translate}}</div>
						<div class="row">
							<div class="text lighter" [id]="idUseSetup">{{'USE_SETUP_APP_DESC_80A' | translate}}.<span class="superscript">TM</span>
							</div>
						</div>
						<div class="row">
							<img src="assets/image/wifi.svg" alt="wi-fi" title="wi-fi">
							<div class="text connect-text" [id]="idWifiNetwork">{{'CONNECT_YOUR_HOME_WI_FI_NETWORK' | translate}}</div>
						</div>
						<div class="row" *ngIf="isUSAUser">
							<img class="small-image" src="assets/image/ic-backup-power.svg" alt="bi-directional" title="bi-directional">
							<div class="text connect-text" [id]="idConnectFordpass">{{'ENABLE_INTELLIGENT_BACKUP_POWER' | translate}}</div>
						</div>
						<div class="row">
							<img src="assets/image/fordpass.svg" alt="connect to FordPass" title="connect to FordPass">
							<div class="text connect-text" id="bidirectional-capablities">{{'CONNECT_YOUR_CHARGE_STATION_TO_YOUR_FORDPASS_ACCOUNT' | translate}}</div>
						</div>
							<br>
					</div>
					</ng-template>
				</div>
			</div>

			<div class="app-setup">
			<div class="bottom-fixed">
				<button class="fmc-button" (click)="showAddWallboxConfirm()" [id]="letsGoBtn">
					{{'LETS_GO' | translate}}<span class="fds-icon fds-font--ford-icons__chevron-right fds-icon--offset-right"></span>
				</button>
				<button class="fmc-text-button fmc-text-button--chevron-right" (click)="addWallboxManually()" id="connectFPManuallyBtn">
					{{'CONNECT_TO_FORDPASS_MANUALLY' | translate}}
				</button>
			</div>
			</div>


		</div>

	</div>
</div>
</div>
<div class="fds-layout-grid__cell--span-12 app-setup-dialog" *ngIf="addWallboxConfirm">
	<p-dialog visible="true" [modal]="true" [showHeader]="false" [style]="{width: '80vw'}" [baseZIndex]="0">
		<p class="setup-confirm-box-text" *ngIf="wallboxType === '48A'; else siemensSetupappConfirmMsg" [innerHTML]="'FORDPASS_WANTS_TO_OPEN_CHARGE_STATION' | translate"></p>
		<ng-template #siemensSetupappConfirmMsg>
			<p class="setup-confirm-box-text"  [innerHTML]="'FORDPASS_WANTS_TO_OPEN_CHARGE_STATION_PRO' | translate"></p>
		</ng-template>
		<div class="setup-config">
			<a (click)="openSetupAppForAddWallbox()" class="setup-confirm-button fds-transform--uppercase" id="openBtn">{{'OPEN' | translate}}</a>
			<a (click)="closeAddWallbox()" class="setup-confirm-button fds-transform--uppercase" id="cancelBtn">{{'CANCEL' | translate}}</a>
		</div>
	</p-dialog>
</div>
<ng-template #tempSetupFlow>
	<div class="fds-layout-grid">
		<div class="fds-layout-grid__inner">
			<div class="fds-layout-grid__cell--span-12">
				<div class="content-position">
					<div class="setup setup-margin">
						<div class="content" *ngIf="wallboxType === '48A'; else siemensWallbox">
							<div class="heading">{{'STEP_1' | translate}}</div>
							<div class="row">
								<div class="text lighter" [id]="idAppDownload">Download the Ford Connected Charge Station<span class="superscript">TM</span> setup app and follow the setup instructions.
								</div>
							</div>
							<div class="row-icon">
								<div class="app-icon">
									<img src="assets/image/app-icon.png" alt="Wallbox" title="Wallbox download">
								</div>
							</div>
							<div class="app-icon text-align-center">
								<button class="fmc-button" (click)="showAddWallboxConfirm()" [id]="letsGoBtn">
									{{'DOWNLOAD_THE_APP' | translate}}<span class="fds-icon fds-font--ford-icons__chevron-right fds-icon--offset-right"></span>
								</button>
							</div>
							<br/><br/>
							<div class="heading">{{'STEP_2' | translate}}</div>
							<div class="row">
								<div class="text lighter" [id]="idConnectFordpass">{{'CONNECT_YOUR_CHARGE_STATION_TO_YOUR_FP_ACC_TO_UNLOCK_WB_FULL_POTENTIAL' | translate}}
								</div>
							</div>
							<div class="margin30TopBottom">
								<div class="row-info">
									<div class="text lighter">
										<img src="assets/image/charge-insights-info.png" class="info-img"
											 alt="View Charging Insights" title="View Charging Insights">
										<span class="info-text">{{'VIEW_CHARGING_INSIGHTS' | translate}}</span>
									</div>
								</div>
								<div class="row-info">
									<div class="text lighter">
										<img src="assets/image/lock-unlock-info.png" class="info-img"
											 alt="Lock & unlock station remotely" title="Lock & unlock station remotely">
										<span class="info-text">{{'LOCK_UNLOCK_STATION_REMOTELY' | translate}}</span></div>
								</div>
							</div>

							<div class="app-icon text-align-center">
								<button class="fmc-button" (click)="addWallboxManually()">
									{{'CONNECT_TO_FORDPASS' | translate}}<span class="fds-icon fds-font--ford-icons__chevron-right fds-icon--offset-right"></span>
								</button>
							</div>
						</div>

						<ng-template #siemensWallbox>
							<div class="content">
								<div class="heading">{{'STEP_1' | translate}}</div>
								<div class="row">
									<div class="text lighter" [id]="idAppDownload">Download the Ford Connected Charge Station Pro<span class="superscript">TM</span> setup app and follow the setup instructions.
									</div>
								</div>
								<div class="row-icon">
									<div class="app-icon">
										<picture class="picture-margin">
											<source media="(min-width: 200px)"
													srcset="assets/image/app-icon-80A-mdpi.png">
											<source media="(min-width: 320px)"
													srcset="assets/image/app-icon-80A-mdpi.png">
											<source media="(min-width: 480px)"
													srcset="assets/image/app-icon-80A-hdpi.png">
											<source media="(min-width: 720px)"
													srcset="assets/image/app-icon-80A-xhdpi.png">
											<source media="(min-width: 960px)"
													srcset="assets/image/app-icon-80A-xxhdpi.png">
											<source media="(min-width: 1440px)"
													srcset="assets/image/app-icon-80A-xxxhdpi.png">
											<img src="assets/image/app-icon-80A-mdpi.png" class="dashboard-banner"
												 alt="Wall Box" title="Wall Box">
										</picture>
									</div>
								</div>
								<div class="app-icon text-align-center">
									<button class="fmc-button" (click)="showAddWallboxConfirm()" [id]="letsGoBtn">
										{{'DOWNLOAD_THE_APP' | translate}}<span class="fds-icon fds-font--ford-icons__chevron-right fds-icon--offset-right"></span>
									</button>
								</div>
								<br/><br/>
								<div class="heading">{{'STEP_2' | translate}}</div>
								<div class="row">
									<div class="text lighter" id="bidirectional-capablities-temp">{{'CONNECT_YOUR_CHARGE_STATION_TO_YOUR_FP_ACC_TO_UNLOCK_WB_FULL_POTENTIAL' | translate}}</div>
								</div>
								<div class="margin30TopBottom">
									<div class="row-info">
										<div class="text lighter">
											<img src="assets/image/charge-insights-info.png" class="info-img"
												 alt="View Charging Insights" title="View Charging Insights">
											<span class="info-text">{{'VIEW_CHARGING_INSIGHTS' | translate}}</span></div>
									</div>
									<div class="row-info">
										<div class="text lighter">
											<img src="assets/image/lock-unlock-info.png" class="info-img"
												 alt="Lock & unlock station remotely" title="Lock & unlock station remotely">
											<span class="info-text">{{'LOCK_UNLOCK_STATION_REMOTELY' | translate}}</span></div>
									</div>
									<div class="row-info">
										<div class="text lighter">
											<img src="assets/image/intelligent-backup-info.png" class="info-img"
												 alt="Enable Intelligent Backup Power" title="Enable Intelligent Backup Power">
											<span class="info-text">{{'ENABLE_INTELLIGENT_BACKUP_POWER' | translate}}</span></div>
									</div>
								</div>

								<div class="app-icon text-align-center">
									<button class="fmc-button" (click)="addWallboxManually()">
										{{'CONNECT_TO_FORDPASS' | translate}}<span class="fds-icon fds-font--ford-icons__chevron-right fds-icon--offset-right"></span>
									</button>
								</div>
							</div>
						</ng-template>


					</div>
				</div>
			</div>

		</div>
	</div>
</ng-template>