import {Inject, Injectable, Optional, PLATFORM_ID} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Wallbox} from '../_models/wallbox';
import {filter, map, tap} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {LoaderState} from '../loader/loader';
import {ToasterBarMessageService} from './toaster-bar-message.service';
import {isPlatformBrowser} from '@angular/common';
import { ICommandRequest } from '../_models/command-request';

@Injectable({
	providedIn: 'root'
})
export class WallboxService {
	private readonly apiUrl = environment.baseUrl;
	private readonly apiChargePointsUrl = this.apiUrl + '/chargepoints/';
	private readonly tokenAPIUrl = environment.tokenAPIUrl;
	private readonly hereMapUrl = 'https://autosuggest.search.hereapi.com/v1/autosuggest';
	private readonly hereMapUrlLookupById = 'https://lookup.search.hereapi.com/v1/lookup';
	private userGuid: string;
	private chargePointData: any;
	private chargePointSettingsData: any;
	private readonly appId = environment.appId;
	private readonly tokenSubject = new Subject<TokenState>();
	tokenState = this.tokenSubject.asObservable();
	private readonly languageSubject = new Subject<string>();
	languageState = this.languageSubject.asObservable();
	private subscription: Subscription;
	private wallboxType: string;
	private isUserFromNA: boolean;
	private isUserFromUSA: boolean;
	wallboxTypeSubject = new Subject<string>();
	wallboxTypeObservable = this.wallboxTypeSubject.asObservable();
	BPTFlagSubject = new Subject<boolean>();
	BPTFlag = this.BPTFlagSubject.asObservable();
	private readonly defaultLanguage = 'en-US';
	private readonly languages = ['cs-CZ', 'da-DK', 'de-DE', 'el-GR', 'en-US', 'es-ES', 'es-US', 'fi-FI', 'fr-CA', 'fr-FR',
		'hu-HU', 'it-IT', 'nb-NO', 'nl-BE', 'nl-NL', 'pl-PL', 'pt-PT', 'ro-RO', 'sl-SI', 'sv-SE'];
	private readonly countries = ['USA', 'MEX', 'CDN', 'CAN', 'GCA', 'CUB', 'HTI', 'DOM', 'HND', 'NIC', 'SLV', 'CRI', 'PAN', 'JAM',
		'TTO', 'BLZ', 'BHS', 'BRB', 'LCA', 'GRD', 'VCT', 'ATG', 'DMA', 'KNA'];
	private catTokenVal: string;
	private hereMapAccessToken: any;

	constructor(private readonly http: HttpClient,
				@Inject(PLATFORM_ID) private platformId: Object,
				private readonly toasterBarMessageService: ToasterBarMessageService) {
		this.wallboxType = '48A';
	}

	getCatTokenByUser(userGuid: string) {
		return this.http.get(`${this.apiUrl}/users/getToken?userGuid=${userGuid}`);
	}

	storeCatToken(catToken: string, refreshToken: string, pageLoad: boolean) {
		sessionStorage.setItem('catToken', catToken);
		sessionStorage.setItem('refreshToken', refreshToken);
		if (pageLoad === true) {
			this.tokenSubject.next({ catToken: catToken, refreshToken: refreshToken } as TokenState);
		}
	}

	getCatToken() {
		if (isPlatformBrowser(this.platformId)) {
			return sessionStorage.getItem('catToken');
		}
	}

	getRefreshToken() {
		if(isPlatformBrowser(this.platformId)) {
			return sessionStorage.getItem('refreshToken');
		}
	}

	storeShowManualSetupFlow(showManualSetupFlow: string) {
		if(isPlatformBrowser(this.platformId)) {
			sessionStorage.setItem('showManualSetupFlow', showManualSetupFlow);
		}
	}

	getShowManualSetupFlow() {
		if(isPlatformBrowser(this.platformId)) {
			return sessionStorage.getItem('showManualSetupFlow');
		}
	}

	getAppId() {
		return this.appId;
	}

	getCatTokenByRefreshToken(params) {
		const headers = new HttpHeaders().set('Application-Id', this.appId);
		return this.http.post<any>(`${this.tokenAPIUrl}`, params, {headers}).pipe(tap((tokens: any) => {
			this.storeCatToken(tokens.access_token, tokens.refresh_token, false);
		}));
	}

	storeUserGuid(userGuid: string) {
		this.userGuid = userGuid;
	}

	getUserGuid() {
		return this.userGuid;
	}

	storeUserLanguage(language: string) {
		if (isPlatformBrowser(this.platformId)) {
			sessionStorage.setItem('language', language);
		}
		this.languageSubject.next(language);
	}

	getUserLanguage() {
		if(isPlatformBrowser(this.platformId)) {
			return sessionStorage.getItem('language');
		}
	}

	storeUserCountry(country: string) {
		if(isPlatformBrowser(this.platformId)) {
			sessionStorage.setItem('country', country);
		}
	}

	getUserCountry() {
		if(isPlatformBrowser(this.platformId)) {
			return sessionStorage.getItem('country');
		}
	}

	storeBPTFlag(BPTFlag: boolean) {
		this.BPTFlagSubject.next(BPTFlag);
	}

	storeAddWallboxData(chargePointData: any) {
		this.chargePointData = chargePointData;
	}

	getUserProfile() {
		return this.http.get(`${this.apiUrl}/users/profile`);
	}

	validateAddress(chargePointAddress) {
		const url = this.apiUrl + '/timezone/address/validate';
		return this.makePutRequestAndValidate(url, chargePointAddress);
	}

	getAddWallboxData() {
		return this.chargePointData;
	}

	getChargePointDetails(chargePointId) {
		const url = `${this.apiChargePointsUrl}${chargePointId}`;
		return this.makeGetRequestAndValidate(url);
	}
	setChargePointSettingsData(data){
		this.chargePointSettingsData = data;
	}
	getChargePointSettingsData(){
		return this.chargePointSettingsData;
	}

	softResetWallbox(chargePointId) {
		const url = `${this.apiChargePointsUrl}${chargePointId}/reset`;
		return this.http.post(url, 'POST');
	}

	getInverterRegStatus(chargePointId) {
		const url = `${this.apiChargePointsUrl}${chargePointId}/inverterRegistrationStatus`;
		return this.http.get(url);
	}

	getInverterGridStatus(chargePointId) {
		const url = `${this.apiChargePointsUrl}${chargePointId}/inverterGridStatus`;
		return this.http.get(url);
	}

	getChargeInsights(chargePointId, reportType, fromDate) {
		if (reportType) {
			return this.http.get(`${this.apiChargePointsUrl}${chargePointId}/chargeInsights?reportType=${reportType}&fromDate=${encodeURIComponent(fromDate)}`);
		} else {
			return this.http.get(`${this.apiChargePointsUrl}${chargePointId}/chargeInsights`);
		}
	}

	getDischargeInsights(chargePointId, reportType, fromDate) {
		return this.http.get(`${this.apiChargePointsUrl}${chargePointId}/dischargeInsights?reportType=${reportType}&fromDate=${encodeURIComponent(fromDate)}`);
	}

	getChargeEvents(chargePointId, reportType, fromDate, toDate, page, fetchLimit) {
		return this.http.get(`${this.apiChargePointsUrl}${chargePointId}/chargeEvents?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}&fetchLimit=${fetchLimit}&page=${page}`);
	}

	getDischargeEvents(chargePointId, fromDate, toDate, page, fetchLimit) {
		return this.http.get(`${this.apiChargePointsUrl}${chargePointId}/dischargeEvents?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}&fetchLimit=${fetchLimit}&page=${page}`);
	}

	getChargePointIdBySerialNumber(serialNumber: string, isManuallyAdded: boolean, accessCode: string, vendorCode: string) {
		return this.http.get(`${this.apiUrl}/chargepoints?serialNumber=${serialNumber}&vendorCode=${vendorCode}&isManuallyAdded=${isManuallyAdded}&accessCode=${accessCode}`);
	}

	addWallboxToAccount(chargePointData) {
		const url = `${this.apiUrl}/users/chargepoints/${chargePointData.chargingStationId}`;
		return this.makePostRequestAndValidate(url, chargePointData);
	}

	saveChargePointSettings(wallbox) {
		return this.http.put(`${this.apiChargePointsUrl}${wallbox.chargingStationId}`, wallbox);
	}

	updateMaxChargeRate(chargePointId, maxChargeRate) {
		return this.http.put(`${this.apiChargePointsUrl}${chargePointId}/maxChargeRate?maxChargeRate=${maxChargeRate}`, '');
	}

	alterChargePointState(chargePointId, chargePointData) {
		return this.http.put(`${this.apiChargePointsUrl}${chargePointId}/availability`, chargePointData);
	}

	removeChargePoint(chargePointId) {
		return this.http.delete(`${this.apiChargePointsUrl}${chargePointId}`);
	}

	getchargePointsList(type) {
		return this.http.get(`${this.apiUrl}/users/chargepoints?type=${type}`);
	}

	inviteUser(data) {
		const url = `${this.apiUrl}/sharedUsers/${data.sharedUserEmail}/chargepoints`;
		return this.makePostRequestAndValidate(url, data);
	}

	inviteSaveUser(data) {
		const url = `${this.apiUrl}/sharedUsers/${data.sharedUserEmail}/chargepoints?resendInvite=false`;
		return this.makePutRequestAndValidate(url, data.chargingStationIdList);
	}

	resendInviteUser(emailId) {
		const url = `${this.apiUrl}/sharedUsers/${emailId}/resendInvite`;
		return this.makePutRequestAndValidate(url, []);
	}

	viewSharedUsers(userId) {
		return this.http.get(`${this.apiUrl}/users/sharedUsers`);
	}

	getSharedUsersImage(emailId) {
		return this.http.get(`${this.apiUrl}/users/image?emailId=${emailId}`);
	}

	viewSharedUsersDetails(emailId) {
		return this.http.get(`${this.apiUrl}/sharedUsers/${emailId}/chargepoints`);
	}

	removeSharedUser(emailId) {
		const url = `${this.apiUrl}/sharedUsers/${emailId}/chargepoints`;
		return this.makeDeleteRequestAndValidate(url);
	}

	getAddressFromHereMap(address) {
		return this.http.get(`${this.hereMapUrl}?at=40.7539,-73.9837&q=${address}&limit=5`);
	}

	fetchHereMapAccessToken() {
		return this.http.get(`${this.apiUrl}/timezone/map/token`);
	}

	storeHereMapAccessToken(hereMapAccessToken) {
		this.hereMapAccessToken = hereMapAccessToken;
	}

	getHereMapAccessToken() {
		return this.hereMapAccessToken;
	}

	getDetailedAddressFromHereMap(id) {
		return this.http.get(`${this.hereMapUrlLookupById}?id=${id}`);
	}

	getUsersChargePointLiveStatuses() {
		return this.http.get(`${this.apiUrl}/users/status/chargepoints`);
	}

	getChargePointPointLiveStatus(chargePointId) {
		return this.http.get(`${this.apiChargePointsUrl}${chargePointId}/status`);
	}

	/**
	 * @description Api service for ocpp commands
	 * @param resetRequest
	 * @returns
	 */
	triggerOcppCommand(commandRequest: ICommandRequest) {
		return this.http.post(this.apiChargePointsUrl + 'ocppCommand', commandRequest);
	}

	/**
	 * @description Api to get the response of the triggered ocpp command
	 * @param commandId
	 * @param uuid
	 * @param commandType
	 * @param ticketId
	 * @returns
	 */
	getCommandResponse(commandId: string, chargingStationId: string, commandType: string) {
		const params = {
			commandId,
			chargingStationId,
			commandType
		};
		return this.http.post(this.apiChargePointsUrl + 'commandStatus', params);
	}

	setChargePointLiveStatusSubscription(subscriptionValue) {
		this.subscription = subscriptionValue;
	}

	getChargePointLiveStatusSubscription() {
		return this.subscription;
	}

	getDefaultLanguage() {
		return this.defaultLanguage;
	}

	getAllLanguages() {
		return this.languages;
	}

	getNACountries() {
		return this.countries;
	}

	setIsUserFromNA(isUserFromNA: boolean) {
		this.isUserFromNA = isUserFromNA;
	}

	getIsUserFromNA() {
		return this.isUserFromNA;
	}

	setIsUserFromUSA(isUserFromUSA: boolean) {
		this.isUserFromUSA = isUserFromUSA;
	}

	getIsUserFromUSA() {
		return this.isUserFromUSA;
	}

	setWallboxType(wallboxType: string) {
		this.wallboxType = wallboxType;
	}

	getWallboxType() {
		return this.wallboxType;
	}

	getFirmwareUpdateStatus(chargePointId) {
		const url = `${this.apiChargePointsUrl}${chargePointId}/firmwareStatus`;
		return this.makeGetRequestAndValidate(url);
	}

	private makePostRequestAndValidate(url: string, body) {
		return this.makeHttpRequest(url, 'POST', { body });
	}
	private makePutRequestAndValidate(url: string, body) {
		return this.makeHttpRequest(url, 'PUT', { body });
	}
	private makeGetRequestAndValidate(url: string) {
		return this.makeHttpRequest(url, 'GET', { });
	}
	private makeDeleteRequestAndValidate(url: string) {
		return this.makeHttpRequest(url, 'DELETE', { });
	}
	makeHttpRequest( url: string, method: string, options) {
		return this.http.request(method, url, options )
			.pipe(filter(response => this.hasResultOrError(response)));
	}
	hasResultOrError(data: any) {
		if (this.hasErrorResponse(data)) {
			this.toasterBarMessageService.handleErrorMessage(data);
			return false;
		} else {
			return true;
		}
	}

	hasErrorResponse(data: any) {
		return (data.result === null && data.error !== null);
	}
}
export interface TokenState {
	catToken: string;
	refreshToken: string;
}
