import { Injectable } from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute} from '@angular/router';
import {WallboxService} from '../_services/wallbox.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
	constructor(private readonly router: Router,
				private readonly route: ActivatedRoute,
				private readonly wallboxService: WallboxService) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (this.wallboxService.getCatToken()) {
			return true;
		} else {
			this.router.navigate(['/app-startup'], { queryParams: { catTokenExists: false } });
			return false;
		}
	}
}
