import {AmplitudeService} from './_services/amplitude.service';
import {Injectable} from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class AppSendEvents {

	constructor(private amplitudeService: AmplitudeService) {
	}

	sendConfirmWallboxSetupEvents(wallboxType: string) {
		const event = 'confirm wallbox setup deeplinked screen viewed';
		const eventProperties = {
			'screenName': 'wallbox setup deeplink',
			'wallboxType': wallboxType === '48A' ? 'WS48A' : 'SWA80A'
		};
		this.amplitudeService.sendEvents(event, eventProperties);
	}

}
