<main id="app-content">
	<p-toast></p-toast>
	<p-toast styleClass="toast-bg custom-toast-ajax" position="top-center" key="cAjax">
		<ng-template let-message pTemplate="message">
			<div class="toast-text">
				<div *ngIf="message.severity === 'error'">
					<img src="assets/image/warning-alert-small.svg" alt="alert" title="alert" class="triangle-alert">
				</div>
				<div *ngIf="message.severity !== 'error'">
					<img src="assets/image/check-circle.svg" alt="success" title="success">
				</div>
				<div class="margin-left17">{{message.summary}}</div>
			</div>
			<div class="rectangle" [ngClass]="message.severity == 'error' ? 'eRed' : 'eGreen'"></div>
		</ng-template>
	</p-toast>
	<app-loader></app-loader>
	<router-outlet></router-outlet>
	<app-footer></app-footer>
</main>